<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser"
import {
  required,
} from "vuelidate/lib/validators";
import { parkingTypeItemMethods } from "../../../state/helpers/parkingtypeitem";
import Swal from "sweetalert2";
/**
 * Blank page component
 */
export default {
  page: {
    title: "Amenity",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      parking_type: {
        value: '',
      },
      is_sort_asc: false,
      is_sort_ascdesc_enable: false,
      active_column: '',
      parking_types: [],
      title: "Parking Types",
      is_update: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      page: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      can_login: false,
      fields: [
        { key: "value", label: "Name"},
      ]
    };
  },
  validations: {
    parking_type: {
      value: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.getAllParkingType()
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    ...parkingTypeItemMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllParkingType() {
      return this.allParkingTypeItem({ paginate: true, page: this.page }).then((response) => {
        // eslint-disable-next-line no-console
        
        this.parking_types = response.data.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        this.totalRows = response.data.total
        
      })
    },
    handlePageChange(page) {
      const field = this.active_column
      this.is_sort_asc = !this.is_sort_asc
      let query;
      if(field != '') {
        query =  { paginate: true, page: this.page, order_field: field, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      }else{
        query = { paginate: true, page }
      }
      return this.allParkingTypeItem(query).then((response) => {
        // eslint-disable-next-line no-console
        this.page = page
        this.parking_types = response.data.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        this.totalRows = response.data.total
        
      })
    },
    searchData(event) {
      // eslint-disable-next-line no-console
      const value = event.target.value
      return this.allParkingTypeItem({ paginate: true, keyword: value , page: this.page}).then((response) => {
        // eslint-disable-next-line no-console
        this.parking_types = response.data.data.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
        this.totalRows = response.data.total
      })
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.is_update = false;
      this.emptyData()
    },
    showDetails(data) {
      this.parking_type = data
      this.is_update = true;
      this.showModal()
    },
    saveParkingType() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        this.createParkingTypeItem(this.parking_type).then(() => {
          this.hide()
          this.getAllParkingType()
        })
      }
      this.submitted = false;
    },
    updateParkingType() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        this.updateParkingTypeItem(this.parking_type).then(() => {
          this.hide()
          this.getAllParkingType()
        })
      }
      this.submitted = false;
    },
    emptyData() {
      this.parking_type = {
        value: ""
      }
    },
    deleteParkingType() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteParkingTypeItem(this.parking_type.id).then(() => {
            this.getAllParkingType()
            this.hide()
          })
        }
      });
    },

    
    async handleTableHeadClicked(item){
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc
      let query = { paginate: true, page: this.page, order_field: item, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      await this.allParkingTypeItem(query).then((response) => {
        // eslint-disable-next-line no-console
        this.parking_types = response.data.data
        this.totalRows = response.data.total
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="mt-5">
      <b-card-body>
        <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
          <h1 class="card-title">Parking Type List</h1>
          <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="showModal">Add New</a>
        </b-card-title>
        <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
    <div class="row" style="flex: 1">
      <div class="col-md-8">
        <label class="form-label" for="validationCustom04">Search for Parking Type</label>
        <input id="validationCustom04"  type="text" class="form-control" @input="searchData"/>
      </div>
    </div>
</b-card-title>
        <b-card-text>
          <!-- Table -->
          <div class="table-responsive mb-0 datatables settings">
            <b-table :items="parking_types" :fields="fields" 
            responsive
            @row-clicked="showDetails"
          id="app-table" striped="striped"
          borderless="borderless" outlined="outlined" fixed="fixed" sticky-header="true"
          @head-clicked="handleTableHeadClicked"
            >
            <template #head()="data">
                        <div class="table_heading_view">
                          <span class="">{{ data.label }}</span>
                          <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip"
                            v-if="is_sort_asc && data.column === active_column"></i>
                          <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip"
                            v-if="!is_sort_asc && data.column === active_column"></i>
                        </div>

                      </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="handlePageChange"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-card-text>

        <!-- user modal -->
        <b-modal id="app-modal" :title="is_update ? 'Update Parking Type Details' : 'Create New Parking Type'"
          title-class="font-18" size="md" hide-footer @hide="hide">
          <div class="row">

            <div class="col-md-12">
              <!-- <h4 class="mb-3 mt-3">Infomation</h4> -->
              <form class="needs-validation">
                <div class="row">

                  <div class="col-md-12">
                    <div class="mb-4">
                      <label class="form-label" for="validationCustom04">Parking Type Name</label>
                      <input id="validationCustom04" v-model="parking_type.value" type="text" class="form-control"
                        placeholder="" :class="{
                          'is-invalid': submitted && $v.parking_type.value.$error,
                        }" />
                      <div v-if="submitted && $v.parking_type.value.$error" class="invalid-feedback">
                        <span v-if="!$v.parking_type.value.required">This value is required.</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="button_view d-flex align-items-center justify-content-between mt-4">
                  <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
                  <button type="button" v-if="is_update" class="btn btn-outline-danger"
              @click="deleteParkingType">Delete</button>
                  <button type="button" class="btn btn-outline-success"
                    @click="is_update ? updateParkingType() : saveParkingType()">Save</button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>

      </b-card-body>
    </b-card>
      </div>
    </div>

  </Layout>
</template>